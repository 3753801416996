img {
    width: 30vw;
    margin: 16px auto;
    display: block;
    align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

code {
    background: lightgray;
    padding: 2px 4px;
    border-radius: 5px;
    font-weight: bold;
}

.slider {
    width: 40vw;
    display: table-cell;
    height: 10px;
}
.label {
    display: table-cell;
    width: 2vw;
    height: 10px;
}

.sliders {
    margin: 10px 0;
}